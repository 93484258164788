<script lang="ts" setup>
import consola from 'consola'

type _LatestPriceFiled = 'aprice' | 'bprice' | 'cprice' | 'dprice' | 'eprice'

const latestPriceFiled = [
  'aprice',
  'bprice',
  'cprice',
  'dprice',
  'eprice',
]

const { addCart } = useAddCart()
const localPath = useLocalePath()
const { t, locale } = useI18n()
const active = ref<number>()
const brandList = useState<_BrandType[]>('_brandList', () => [])

const authStore = useAuthStore()
const user = useState<_JwtPayload | null>('user')

const userinfo = computed(() => {
  try {
    return user.value
  }
  catch (error) {
    consola.error(error)
    return authStore.userinfo
  }
})

const priceFiled = computed<_LatestPriceFiled>(() => {
  let filed = 'aprice'
  if (userinfo.value && userinfo.value.mbType) {
    filed = `${userinfo.value.mbType.toLocaleLowerCase()}price`
    if (!latestPriceFiled.includes(filed)) {
      filed = 'aprice'
    }
  }
  return filed as _LatestPriceFiled
})

await useLazyAsyncData<_ResponseType<_BrandType[]>>(`brand/listForCustomer`, () => $fetch(
  '/api/brand/listForCustomer',
  {
    retry: 3,
    retryDelay: 2000,
    params: {
      language: locale.value === 'en' ? 'en' : 'cn',
    },
    onResponse({ response }) {
      const { code, data: brands } = response._data
      if (code && code === 200) {
        brandList.value = brands.map((item: _BrandType) => {
        // 扁平 ibsProducts 数组中的 commodityList 数组
          const commodityList = item.ibsProducts.map((product: _BrandType['ibsProducts'][number]) => {
            return product.commodityList || []
          }).flat()

          return {
            ...item,
            commodityList,
          }
        })
      }
    },
  },
), {
  getCachedData(key, nuxtApp) {
    return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
  },
})

function productDetail(code: string) {
  navigateTo(localPath(`/product/${code}`))
}

function handleJoinCart(product: _CommodityList) {
  addCart({
    count: 1,
    cat: product.cat,
    scode: product.scode,
    img: product.path1,
    price: Number(product[priceFiled.value]),
    name: product.cdescript,
    nameEn: product.descript,
  })
}
</script>

<template>
  <div class="mt-4 bg-#f7f7f7 p-4">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center text-lg font-600">
        <svgo-brands filled class="mr-2 text-2xl" />
        <div>{{ t("Brand counter") }}</div>
      </div>
    </div>

    <div class="mt-5">
      <van-tabs v-model:active="active" animated type="card">
        <template v-for="item in brandList" :key="item.id">
          <van-tab :name="item.id">
            <template #title>
              {{ item.name }}
            </template>
            <div class="grid grid-cols-2 mt-5 mt-5 gap-x-4 gap-y-6">
              <div
                v-for="product in item.commodityList"
                :key="product.scode"
                class="product-item"
              >
                <NuxtImg
                  class="aspect-ratio-square rounded-2xl"
                  :src="product.path1"
                  :alt="product.cdescript"
                  @click="productDetail(product.scode)"
                />
                <div
                  mt-2.5
                  font-size-14px
                >
                  {{ product.cdescript }}
                </div>
                <div class="price flex items-end justify-between">
                  <div
                    flex
                    items-baseline
                  >
                    <div class="font-size-16px font-500">
                      <LazyPrice :price="product[priceFiled]" />
                    </div>
                  </div>
                  <svgo-shopcar
                    filled
                    class="text-xl"
                    @click="handleJoinCart(product)"
                  />
                </div>
              </div>
            </div>
          </van-tab>
        </template>
      </van-tabs>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.van-tabs--card) {
  .van-tabs__wrap {
    --van-tabs-card-height: 34px;
  }
}
:deep(.van-tab) {
  --van-tab-line-height: 34px;
}
:deep(.van-tabs__nav) {
  --van-tabs-nav-background: transparent;
  column-gap: 10px;
}
:deep(.van-tabs__nav--card) {
  --van-border-width: 0;
  .van-tab--card {
    color: #743a3a;
    background-color: #f2e9ea;
    border-radius: 9px;
  }
}
:deep(.van-tab--card.van-tab--active) {
  background-color: #743a3a;
  color: white;
}
</style>
